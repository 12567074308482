import React from 'react';

export const authRouteConfig = [
  {
    path: '/signin',
    component: React.lazy(() => import('./Signin/index')),
  },
  {
    path: '/signup',
    component: React.lazy(() => import('./Signup/index')),
  },
  {
    path: '/forget-password',
    component: React.lazy(() =>
      import('./ForgetPassword/ForgetPasswordAwsCognito'),
    ),
  },
  {
    path: '/confirm-signup',
    component: React.lazy(() => import('./ConfirmSignupAwsCognito')),
  },
  {
    path: '/reset-password',
    component: React.lazy(() => import('./ResetPasswordAwsCognito')),
  },
  {
    path: '/new-password',
    component: React.lazy(() => import('./NewPasswordAwsCognito')),
  },
  {
    path: '/forget-submit',
    component: React.lazy(() =>
      import('./ForgetPassword/ForgetPasswordSubmitAwsCognito'),
    ),
  },
];
