import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Auth from '@aws-amplify/auth';
import {setAuthToken} from '../jwt-auth/index';
import PropTypes from 'prop-types';
import {awsConfig} from './aws-exports';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
} from '../../../../shared/constants/ActionTypes';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

const AwsCognitoContext = createContext();
const AwsCognitoActionsContext = createContext();

export const useAwsCognito = () => useContext(AwsCognitoContext);

export const useAwsCognitoActions = () => useContext(AwsCognitoActionsContext);

const AwsAuthProvider = ({children}) => {
  const [awsCognitoData, setAwsCognitoData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    tempSession: null,
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const auth = useMemo(() => {
    Auth.configure(awsConfig);
    return Auth;
  }, []);

  useEffect(() => {
    auth
      .currentAuthenticatedUser()
      .then((user) =>
        setAwsCognitoData({
          user,
          isAuthenticated: true,
          isLoading: false,
        }),
      )
      .catch(() =>
        setAwsCognitoData({
          user: undefined,
          isAuthenticated: false,
          isLoading: false,
        }),
      );
  }, [auth]);

  const signIn = async ({email, password}) => {
    dispatch({type: FETCH_START});
    try {
      const user = await Auth.signIn(email, password);

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setAwsCognitoData({
          user: null,
          isLoading: false,
          isAuthenticated: false,
          tempSession: user,
        });
        dispatch({type: FETCH_SUCCESS});
        console.log('User requires new password.', user);
        history.push('/new-password', {tab: 'cognito-tab', email: email});
      } else {
        console.log('user: ', user);
        dispatch({type: FETCH_SUCCESS});
        // Set the header
        setAuthToken(user.signInUserSession.accessToken.getJwtToken());
        setAwsCognitoData({
          user: user,
          isLoading: false,
          isAuthenticated: true,
        });
      }
    } catch (error) {
      console.log('Error:', error);
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const newPassword = async ({email, password, newpassword}) => {
    dispatch({type: FETCH_START});
    try {
      let user;
      let em;
      user = awsCognitoData.tempSession ?? null;
      if (!email) {
        em = history.location.state.email ?? null;
      } else {
        em = email;
      }
      if (!user) {
        if (!em) {
          try {
            user = await Auth.currentSession();
          } catch (error) {
            // User must login
            dispatch({type: FETCH_ERROR, payload: error.message});
            history.push('/sign-in');
          }
        } else {
          user = await Auth.signIn(em, password);
        }
      }
      const reslt = await Auth.completeNewPassword(user, newpassword);
      console.log('CompleteNewPassword result: ', reslt);
      if (!em) {
        em = reslt.username;
      }
      const nu = await Auth.signIn(em, newpassword);
      console.log('User: ', nu);
      dispatch({type: FETCH_SUCCESS});
      setAwsCognitoData({
        user: nu,
        isLoading: false,
        isAuthenticated: true,
        tempSession: null,
      });
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
        tempSession: null,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const resetPassword = async ({email, password, newpassword}) => {
    dispatch({type: FETCH_START});
    try {
      let user;
      if (!email) {
        user = await Auth.currentSession();
      } else {
        user = await Auth.signIn(email, password);
      }
      const reslt = await Auth.changePassword(user, password, newpassword);
      console.log('ChangePassword result: ', reslt);
      dispatch({type: FETCH_SUCCESS});
    } catch (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const signUpCognitoUser = async ({email, password, phone}) => {
    dispatch({type: FETCH_START});
    try {
      const reslt = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          preferred_username: email,
          phone_number: phone,
        },
      });
      console.log('AWS Signup for ' + email, reslt);
      dispatch({type: FETCH_SUCCESS});
      history.push('/confirm-signup', {email: email});
      dispatch({
        type: SHOW_MESSAGE,
        payload:
          'A code has been sent to your registered email address, Enter the code to complete the signup process!',
      });
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      console.log('Error signup:', error);
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const confirmCognitoUserSignup = async (username, code) => {
    dispatch({type: FETCH_START});
    try {
      await Auth.confirmSignUp(username, code, {
        forceAliasCreation: false,
      });
      history.replace('/signin');
      dispatch({
        type: SHOW_MESSAGE,
        payload:
          'Congratulations, Signup process is complete, You can now Sign in by entering correct credentials!',
      });
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const forgotPasswordSubmit = async (username, code, newpassword) => {
    dispatch({type: FETCH_START});
    try {
      await Auth.forgotPasswordSubmit(username, code, newpassword);
      history.replace('/signin');
      dispatch({
        type: SHOW_MESSAGE,
        payload: 'Your password has been reset, please use it now to sign in.',
      });
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const forgotPassword = async (username) => {
    dispatch({type: FETCH_START});
    try {
      const reslt = await Auth.forgotPassword(username);
      console.log('Forgot password:', reslt);
      let msg = '';
      if (reslt) {
        if (reslt.CodeDeliveryDetails.DeliveryMedium === 'EMAIL') {
          msg = 'Please check your email for the security code.';
        } else if (reslt.CodeDeliveryDetails.DeliveryMedium === 'SMS') {
          msg = 'Please check your phone for the security code.';
        } else {
          msg = 'Please look for your security code we just sent you.';
        }
        dispatch({
          type: SHOW_MESSAGE,
          payload: msg,
        });
        history.push('/forget-submit', {email: username});
      }
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
      dispatch({type: FETCH_ERROR, payload: error.message});
    }
  };

  const logout = async () => {
    setAwsCognitoData({...awsCognitoData, isLoading: true});
    try {
      await auth.signOut();
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    } catch (error) {
      setAwsCognitoData({
        user: null,
        isLoading: false,
        isAuthenticated: false,
      });
    }
  };

  return (
    <AwsCognitoContext.Provider
      value={{
        ...awsCognitoData,
        auth,
      }}
    >
      <AwsCognitoActionsContext.Provider
        value={{
          logout,
          signIn,
          signUpCognitoUser,
          confirmCognitoUserSignup,
          forgotPassword,
          forgotPasswordSubmit,
          newPassword,
          resetPassword,
        }}
      >
        {children}
      </AwsCognitoActionsContext.Provider>
    </AwsCognitoContext.Provider>
  );
};

export default AwsAuthProvider;

AwsAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
